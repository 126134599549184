.CustomSelectSmallWrapper {
  position: relative;
  display: inline-block;
  margin: 3px 3px;
}
.CustomSelectSmall {
  padding: 3px 15px;
  white-space: nowrap;
  border-radius: 100px;
  border: none;
  outline: none;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  transition: transform 40ms, opacity 40ms;
}
.CustomSelectSmall:hover {
  opacity: 0.5;
}
.CustomSelectSmall:active {
  transform: scale(0.95);
}
