.ModalOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  box-sizing: border-box;
  overflow: scroll;
  left: 0;
  top: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  display: block;
}

.ModalInputWrapper {
  margin-bottom: 10px;
}
.ModalButtonWrapper {
  padding-top: 25px;
}

.ModalSubtitle {
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0 10px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
}
.ModalSectionHeader {
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0 10px;
  color: rgb(142, 142, 147);
  font-size: 14px;
  font-weight: 500;
}
.ModalText {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5;
}
.ModalTextBold {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}
.ModalQuote {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 10px;
  padding: 0 25px;
  border-left: 2px solid #f7f7f7;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ModalAnchor {
  text-decoration: none;
  font-weight: 500;
  color: #4b7bec;
}
