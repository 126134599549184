.ProfilePhoto {
  position: relative;
  border-radius: 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProfilePhotoPlaceholder {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 1;
}
