.ProfileButtonWrapper {
  margin-top: 15px;
}
.ProfileContentWrapper {
  width: 100%;
  max-width: 420px;
  padding: 0 35px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  min-height: 100%;
  box-sizing: border-box;
}
.ProfileContentContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
}
.ProfileInputWrapper {
  margin-bottom: 10px;
}
.ProfilePhotoWrapper {
  width: 100%;
  position: relative;
  padding-bottom: 35px;
}
.ProfilePhotoContainer {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #f7f8fa;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.ProfilePhoto {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
}
.ProfilePhotoLabel {
  font-size: 14px;
  color: #a5b1c2;
  padding: 0 25px;
}
.ProfilePhotoIndicatorBadge {
  position: absolute;
  top: 55%;
  left: 65%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  background-color: #eb3b5a;
  border-radius: 50%;
}
.ProfileImageInputComponent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  outline: none;
  border: none;
  cursor: pointer;
}
