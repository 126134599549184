.ChatsMenuItem {
  position: relative;
  background-color: #fff;
  transition: background-color 40ms;
}
.ChatsMenuItem:hover {
  background-color: #fafafa;
}

.ChatsMenuPlusContainer {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  color: #4b7bec;
  transition: background-color 40ms, color 40ms;
}
.ChatsMenuPlusContainer:hover {
  background-color: #f7f7f7;
  color: #3867d6;
}
