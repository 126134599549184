.ShimmerLoadingBar {
  position: relative;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.02) 0%,
    rgba(0, 0, 0, 0.02) 35%,
    #4b7bec 50%,
    rgba(0, 0, 0, 0.02) 65%,
    rgba(0, 0, 0, 0.02) 100%
  );
  background-size: 300% 100%;
  animation: shimmer 3000ms linear infinite;
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
