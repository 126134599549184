.CustomInput {
  border-radius: 100px;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  box-sizing: border-box;
  transition: transform 80ms;
}
.CustomInputIconWrapper {
  width: 40px;
  height: 40px;
  position: relative;
  font-size: 12px;
}
.CustomInputIconContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.CustomInputField {
  position: relative;
  flex-grow: 1;
  background: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
}
.CustomInputField::placeholder {
  color: #fff;
  opacity: 0.5;
}
.CustomInputField.Inverted::placeholder {
  color: #000;
}
.CustomInputErrorText {
  opacity: 0.85;
  padding: 5px 10px 0 10px;
  font-size: 14px;
}
/* Remove up and down arrows in number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
