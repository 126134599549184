.DedicatedLoginWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f7f7f7;
  background: linear-gradient(175deg, #2a64ad 0%, #2a64ad 65%, #fff 65%, #fff 100%);
  display: flex;
  position: relative;
}
.DedicatedLoginContainer {
  position: relative;
  flex-grow: 1;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
  padding-bottom: 10px;
  flex-direction: column;
  display: flex;
}
.DedicatedLoginLogo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 0;
  margin-right: 18px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}
