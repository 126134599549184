.CollapsibleListContainer {
  position: relative;
  background-color: #f7f7f7;
  border-radius: 8px;
  z-index: 1;
  transition: background-color 40ms;
  padding: 0 5px;
}
.CollapsibleListContainer:hover {
  background-color: #eee;
}
