.CustomButtonWrapper {
  position: relative;
  transition: opacity 40ms;
}
.CustomButtonWrapper:hover {
  opacity: 1;
}
.CustomButton {
  padding: 10px 35px;
  white-space: nowrap;
  border-radius: 100px;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  transition: transform 80ms;
  cursor: pointer;
}
.CustomButton:hover {
  opacity: 0.9;
}
.CustomButton:active {
  transform: scale(0.95);
}
