.ImageViewerOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3;
  box-sizing: border-box;
  overflow: scroll;
  left: 0;
  top: 0;
  transition: opacity 200ms;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  padding-bottom: 10px;
  display: block;
  display: flex;
}
.ImageViewerTopBarWrapper {
  position: absolute;
  z-index: 2;
  top: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.ImageViewerButton {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.ImageViewerButtonIcon {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
}
