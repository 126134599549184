.SettingsContentWrapper {
  width: 100%;
  max-width: 460px;
  padding: 0 35px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  min-height: 100%;
  box-sizing: border-box;
}
.SettingsContentContainer {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
}
.SettingsButtonWrapper {
  margin-top: 10px;
}
.SettingsSectionHeader {
  padding: 45px 15px 5px 15px;
  color: rgb(142, 142, 147);
  font-size: 14px;
  font-weight: 500;
}
.SettingsBanner {
  margin-top: 25px;
  padding: 10px 20px;
  background-color: rgba(235, 59, 90, 0.1);
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  position: relative;
}
.SettingsIndicatorBadge {
  position: absolute;
  top: 3px;
  right: 3px;
  transform: translate(50%, -50%);
  width: 15px;
  height: 15px;
  background-color: #eb3b5a;
  border-radius: 50%;
  border: 2px solid #fff;
}
