.OptionSwitchItem {
  position: relative;
  display: block;
  border-radius: 12px;
  overflow: hidden;
  background-color: transparent;
  transition: color 200ms;
  transition: background-color 40ms;
}
.OptionSwitchItem:hover {
  background-color: #eee;
}
