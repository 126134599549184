.ManageButtonWrapper {
  margin-top: 15px;
}
.ManageContentWrapper {
  width: 100%;
  max-width: 540px;
  padding: 0 10px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  box-sizing: border-box;
}
.ManageUserPreview:not(:last-child) {
  margin-bottom: 5px;
}
.UnitPreview:not(:last-child) {
  border-bottom: 1px solid #f7f7f7;
}
.ManageTransaction:not(:last-child) {
  border-bottom: 1px solid #f7f7f7;
}
.UserImageWrapper {
  position: relative;
  width: 50px;
}

.ManageSelectorTab {
  position: relative;
  float: left;
}
.ManageSelectorTabContainer:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
