.PendingAuthOverlayWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #2a64ad;
  z-index: 10000;
  overflow: hidden;
  box-sizing: border-box;
  will-change: opacity;
}
.PendingAuthOverlayContainer {
  background-color: #2a64ad;
  width: 100%;
  height: 100%;
}
.PendingAuthOverlayLogo {
  will-change: transform;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
