.AuthenticationTitle {
  font-size: 28px;
  text-align: center;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Roboto Slab", sans-serif;
}
.AuthenticationInputWrapper {
  margin-bottom: 10px;
}
.AuthenticationButtonWrapper {
  padding-top: 10px;
  padding-bottom: 15px;
}

.AuthenticationSeparationTextWrapper {
  margin-top: 25px;
  margin-bottom: 15px;
  position: relative;
  text-align: center;
}
.AuthenticationSeparationTextLine {
  height: 2px;
  width: 100%;
  background-color: #f7f7f7;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.AuthenticationSeparationText {
  background-color: #fff;
  display: inline-block;
  position: relative;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.AuthenticationTextWrapper {
  padding-bottom: 10px;
  text-align: center;
  color: #2a3950;
  font-size: 14px;
}
.AuthenticationTextLight {
  color: rgba(0, 0, 0, 0.5);
}
.AuthenticationText {
  color: rgba(0, 0, 0, 0.85);
}
.AuthenticationTextBold {
  color: #2a3950;
}

.AuthenticationButtonsWrapper {
  display: flex;
  padding-bottom: 15px;
}
.AuthenticationLeftButtonWrapper {
  width: 50%;
  padding-right: 5px;
}
.AuthenticationRightButtonWrapper {
  width: 50%;
  padding-left: 5px;
}

.AuthenticationRequirementsWrapper {
  padding: 0 10px;
  padding-bottom: 15px;
}

/* AddProfilePhoto */

/*
ProfileImageInputComponent
ProfilePhotoWrapper
ProfilePhotoContainer
ProfilePhotoLabel
ProfilePhoto
*/

.AuthenticationPhotoWrapper {
  width: 100%;
  position: relative;
  padding: 15px 0;
}
.AuthenticationPhotoContainer {
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #f7f8fa;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
}
.AuthenticationPhoto {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
}
.AuthenticationPhotoLabel {
  font-size: 14px;
  color: #a5b1c2;
  padding: 0 25px;
}
.AuthenticationImageInputComponent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  outline: none;
  border: none;
  cursor: pointer;
}
