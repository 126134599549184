.GlobalNavigationTopBar {
  position: relative;
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  padding-left: 10px;
  padding-right: 10px;
  z-index: 2;
}

.GlobalNavigationBottomBar {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  z-index: 2;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.GlobalNavigationTopBarBottomItems {
  display: none;
  flex-direction: row-reverse;
}

.GlobalNavigationLogo {
  width: 18px;
  height: 18px;
  position: relative;
  margin: 0;
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

/* Show top buttons and hide bottom buttons with a narrow screen */
@media only screen and (min-width: 600px) {
  .GlobalNavigationTopBarBottomItems {
    display: flex;
  }
  .GlobalNavigationBottomBar {
    display: none;
  }
}
