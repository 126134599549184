.ClickableButton {
  display: block;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  -ms-touch-action: manipulation; /* IE10  */
  touch-action: manipulation; /* IE11+ */
  color: inherit;
  font-family: inherit;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}
