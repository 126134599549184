.GlobalProgressIndicatorWrapper {
  top: 0;
  position: absolute;
  width: 100%;
  pointer-events: none;
  touch-action: none;
  z-index: 9000;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
}
