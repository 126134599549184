.LandingWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}
.LandingLogo {
  width: 28px;
  height: 28px;
  position: relative;
  margin: 0;
  margin-right: 18px;
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
  opacity: 0.99;
}

/* Header */
.LandingHeaderLeft {
  width: 50%;
  box-sizing: border-box;
}
.LandingHeaderRight {
  width: 50%;
  box-sizing: border-box;
}
.LandingHeaderSubtitle {
  font-size: 22px;
}

/* Getting started */
.LandingGettingStartedBlurb {
  width: 100%;
  padding: 15px 25px;
  margin-bottom: 15px;
  box-sizing: border-box;
  font-size: 28px;
  text-align: center;
  font-family: "Roboto Slab", sans-serif;
}
.LandingGettingStartedContainer {
  width: 100%;
  max-width: 1024px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  padding: 15px 45px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 35px;
}
.LandingGettingStartedItem {
  position: relative;
  width: 33.3333%;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Roboto", sans-serif;
}
.LandingGettingStartedImageWrapper {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}
.LandingGettingStartedImage {
  width: 100%;
}
.LandingGettingStartedText {
  text-align: center;
  color: #4b6584;
  margin-top: auto;
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.15;
  font-family: "Roboto", sans-serif;
}

/* Content */
.LandingContentItem {
  position: relative;
  width: 100%;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.LandingContentContainer {
  padding: 0 45px;
  padding-top: 105px;
  background-color: #f8f9fa;
  border-top: 2px solid #f7f7f7;
}
.LandingContentContainer:nth-child(1) {
  background: linear-gradient(130deg, #fff 0%, #fff 60%, #2a64ad 60%, #2a64ad 100%);
}
.LandingContentContainer:nth-child(2) {
  background: linear-gradient(-130deg, #fff 0%, #fff 60%, #2a3950 60%, #2a3950 100%);
}
.LandingContentContainer:nth-child(3) {
  background: linear-gradient(130deg, #fff 0%, #fff 60%, #ff6584 60%, #ff6584 100%);
}
.LandingContentContainer:nth-child(4) {
  background: linear-gradient(-130deg, #fff 0%, #fff 60%, #2a64ad 60%, #2a64ad 100%);
}
.LandingContentContainer:nth-child(5) {
  background: linear-gradient(130deg, #fff 0%, #fff 60%, #2a3950 60%, #2a3950 100%);
}
.LandingContentContainer:nth-child(6) {
  background: linear-gradient(-130deg, #fff 0%, #fff 60%, #ff6584 60%, #ff6584 100%);
}
.LandingContentContainer:nth-child(even) > .LandingContentItem {
  flex-direction: row;
}
.LandingContentSmall,
.LandingContentLarge {
  position: relative;
  box-sizing: border-box;
  padding: 15px 25px;
  overflow: hidden;
}
.LandingContentSmall {
  width: 50%;
  min-height: 420px;
}
.LandingContentLarge {
  width: 50%;
}
.LandingContentTitle {
  font-size: 28px;
  font-family: "Roboto Slab", sans-serif;
  margin-top: 25px;
  margin-bottom: 15px;
  line-height: 1.2;
  color: #2a64ad;
  font-weight: 400;
}
.LandingContentText {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  margin-bottom: 35px;
  color: #4b6584;
}
.LandingContentScreenshot {
  position: absolute;
  width: 70%;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  background-color: lightgray;
}

/* Footer */
.LandingFooter {
  width: 100%;
  background-color: #2a3950;
  display: flex;
  flex-direction: column;
  padding: 35px 25px;
  font-family: "Roboto Slab", sans-serif;
  justify-content: center;
  box-sizing: border-box;
  color: #fff;
  font-size: 18px;
}
.LandingFooterSupport {
  font-size: 14px;
  text-align: center;
  padding: 10px 5px 15px 5px;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.7);
}
.LandingFooterSupport > a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  white-space: nowrap;
}
.LandingFooterBottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.LandingSocialButton {
  position: relative;
  width: 40px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  transition: background-color 80ms;
}
.LandingSocialButton:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.LandingSocialButtonInner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.LandingCopyright {
  height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  transition: background-color 80ms;
}
.LandingCopyright:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

/* Responsive */
@media only screen and (max-width: 720px) {
  .LandingHeaderLeft,
  .LandingHeaderRight {
    width: 100%;
  }
  .LandingHeaderSubtitle {
    font-size: 18px;
  }
  .LandingGettingStartedItem {
    width: 100%;
  }
  .LandingContentSmall,
  .LandingContentLarge {
    width: 100%;
  }
  .LandingContentSmall {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .LandingContentContainer {
    padding: 0 25px;
    padding-top: 35px;
  }
  .LandingContentContainer:nth-child(odd) {
    background: #f7f7f7;
  }
  .LandingContentContainer:nth-child(even) {
    background: #fff;
  }
  .LandingFooter {
    justify-content: center;
  }
}
