.CustomRadioWrapper {
  padding: 0px 10px;
}
.CustomRadio {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin: 3px 0;
}
.CustomRatioInput {
}
.CustomRadioLabel {
  font-family: "Roboto", sans-serif;
  padding: 3px 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}
