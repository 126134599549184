@font-face {
  font-family: "Roboto Slab";
  src: url("assets/fonts/RobotoSlab-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Regular.ttf") format("truetype");
}

html,
body,
#root {
  position: relative;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* Prevent text selection by default */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

/* Override and allow text selection on particular elements, for example:
   messages, work order subjects, payment descriptions, landing page content, privacy policy, terms of service */
.AllowTextSelection {
  -webkit-touch-callout: text !important;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-overflow-scrolling: touch !important;
}

textarea,
input {
  font-family: inherit;
}
