/* ChatEmojiBar */

.ChatEmojiBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: flex-start;
  padding: 0 10px;
}
.ChatEmojiWrapper {
  flex-grow: 1;
}

/* On narrow screens, remove the horizontal padding around the feed's content */
@media screen and (min-width: 520px) {
  .ChatEmojiBar {
    justify-content: flex-start;
  }
  .ChatEmojiWrapper {
    max-width: 50px;
  }
}

.ChatEmoji {
  font-size: 18px;
  text-align: center;
  padding: 10px 0;
  transition: transform 40ms;
}
.ChatEmoji:hover {
  transform: scale(1.1);
}
.ChatEmoji:active {
  transform: scale(0.9);
}

.ChatComposeIconButton {
  position: relative;
  transition: color 200ms;
  background-color: transparent;
  transition: background-color 40ms, color 200ms;
}
.ChatComposeIconButton:hover {
  background-color: #eee;
}
