.IconButtonWrapper {
  background: #fff;
  border: none;
  box-sizing: border-box;
  outline: none;
  position: relative;
  cursor: pointer;
  display: flex;
  overflow: visible;
  transition: color 200ms, background-color 40ms;
}
.IconButtonWrapper:hover {
  background: #fafafa;
}

/* Add padding to the bottom of buttons when they're displayed on the bottom */
@media only screen and (max-width: 600px) {
  .IconButtonWrapper {
    padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
  }
}
