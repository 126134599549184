.CustomSelect {
  border-radius: 100px;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  box-sizing: border-box;
  transition: transform 80ms;
}
.CustomSelectIconWrapper {
  width: 40px;
  height: 40px;
  position: relative;
  font-size: 12px;
  flex-shrink: 0;
}
.CustomSelectIconContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.CustomSelectField {
  position: relative;
  flex-grow: 1;
  background: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  height: 100%;
  width: 100%;
}
/* Remove select arrows */
.CustomSelectField::-ms-expand {
  display: none;
}
.CustomSelectField {
  -webkit-appearance: none;
  appearance: none;
}
.CustomSelectField::placeholder {
  color: #fff;
  opacity: 0.5;
}
.CustomSelectField.Inverted::placeholder {
  color: #000;
}
.CustomSelectErrorText {
  opacity: 0.85;
  padding: 5px 10px 0 10px;
  font-size: 14px;
}
