@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.LoadingIcon {
  animation: spin 1000ms linear infinite;
}
