.CustomCheckbox {
  font-size: 14px;
  padding-left: 40px;
  position: relative;
}
.DisplayCheckbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  border-radius: 8px;
  background-color: #eee;
  transition: background-color 200ms;
  overflow: hidden;
}
.CustomCheckboxInput {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 26px;
  height: 26px;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
  opacity: 0;
}

/* On mouse-over, add a grey background color */
.CustomCheckbox:hover .CustomCheckboxInput ~ .DisplayCheckbox {
  background-color: #ddd;
}

/* When the checkbox is checked, add a blue background */
.CustomCheckbox .CustomCheckboxInput:checked ~ .DisplayCheckbox {
  background-color: #4b7bec;
}

/* Create the checkmark/indicator (hidden when not checked) */
.DisplayCheckbox:after {
  content: "";
  position: absolute;
  top: -26px;
  display: block;
  left: 10px;
  transition: top 200ms, left 200ms;
}

/* Show the checkmark when checked */
.CustomCheckbox .CustomCheckboxInput:checked ~ .DisplayCheckbox:after {
  top: 6px;
  left: 10px;
}

/* Style the checkmark/indicator */
.CustomCheckbox .DisplayCheckbox:after {
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.CustomCheckboxLabel {
  font-family: "Roboto", sans-serif;
  padding: 3px 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}
